import Typography from "typography";
import { createGlobalStyle } from "styled-components";

export const typography = new Typography({
  title: "FrondTypeFace",
  baseFontSize: "19px",
  baseLineHeight: 1.45,
  headerFontFamily: ["Poppins", "sans-serif"],
  bodyFontFamily: ["Poppins", "Arial", "sans-serif"],
  headerWeight: 400,
  bodyWeight: 400,
  scaleRatio: 1,
});

export default typography;
// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles();
}

export const rhythm = typography.rhythm;
export const scale = typography.scale;

export const FontContainer = createGlobalStyle`

body main, body , #___gatsby{
  min-width: 100vw;
}
a {
  text-decoration:none !important;
}
.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}


`;
