import { Grommet } from "grommet";
import React from "react";
import { Helmet } from "react-helmet";
import { FontContainer } from "../../config/typography";
import GlobalDiv from "./global.less";
import themeVariables from "./theme";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertMUITemplate from "react-alert-template-mui";

import AlertTemplate from "react-alert-template-basic";
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_CENTER,
  timeout: 0,
  offset: "30px",
  // you can also just use 'scale'
  transition: transitions.SCALE,
};

export default function SiteLayout(props) {
  return (
    <>
      <Grommet theme={themeVariables} full>
        <Helmet title="OneLink">
          <link
            href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap"
            rel="stylesheet"
          />
        </Helmet>

        <>
          <GlobalDiv />
          <FontContainer />
          <AlertProvider template={AlertMUITemplate} {...options}>
            <main>{props.children}</main>
          </AlertProvider>
        </>
      </Grommet>
    </>
  );
}
